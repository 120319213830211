<template>
  <div>
    <b-alert
      :show="form.errors && form.errors.length > 0"
      variant="light"
      class="alert red lighten-4"
      ref="alert" 
    > 
    <!-- message -->
      <div class="alert-icon">
        <i class="flaticon-warning kt-font-danger"></i>
      </div>
      <div class="alert-text">
        <div v-for="err in form.errors" :key="err">
          {{ err }}
        </div>
      </div>
    </b-alert>
    <div class="row">
      <div class="col-md-12">
        <KTPortlet v-bind:title="title">
          <template v-slot:body>
            <v-form 
              ref="form" 
              @submit.prevent="submitForm"
              v-model="form.valid"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="form.branchCode"
                    :disabled="form.loading"
                    :counter="50"
                    :label="$t('BRANCH.ADD_EDIT.BRANCH_CODE')"
                    :rules="form.branchCodeRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="form.nameEN"
                    :disabled="form.loading"
                    :counter="200"
                    :label="$t('BRANCH.ADD_EDIT.NAME_EN')"
                    :rules="form.nameENRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="form.nameTH"
                    :disabled="form.loading"
                    :counter="200"
                    :label="$t('BRANCH.ADD_EDIT.NAME_TH')"
                    :rules="form.nameTHRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="form.floor"
                    :disabled="form.loading"
                    :counter="100"
                    :label="$t('BRANCH.ADD_EDIT.FLOOR')"
                    :rules="form.floorRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="form.phoneNumber"
                    :disabled="form.loading"
                    :counter="50"
                    :label="$t('BRANCH.ADD_EDIT.PHONE_NUMBER')"
                    :rules="form.phoneNumberRules"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="form.latitude"
                    :disabled="form.loading"
                    :counter="50"
                    :label="$t('BRANCH.ADD_EDIT.LATITUDE')"
                    :rules="form.latitudeRules"
                    type="number"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="form.longitude"
                    :disabled="form.loading"
                    :counter="50"
                    :label="$t('BRANCH.ADD_EDIT.LONGITUDE')"
                    :rules="form.longitudeRules"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="13"
                  md="6"
                >
                  <v-file-input 
                    accept="image/*"
                    show-size
                    v-model="form.picture"
                    :disabled="form.loading"
                    :label="$t('BRANCH.ADD_EDIT.PICTURE')"
                    :hint="$t('BRANCH.ADD_EDIT.PICTURE_HINT')"
                    persistent-hint
                  ></v-file-input>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  v-if="form.pictureUrl"
                >
                  <v-img 
                    :v-model="form.pictureUrl"
                    :src="form.pictureUrl" 
                    :disabled="form.loading"
                    aspect-ratio="1.7" 
                    contain
                  >
                  </v-img>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <v-checkbox
                    v-model="form.isTravelCity"
                    :disabled="form.loading"
                    :label="$t('BRANCH.ADD_EDIT.IS_TRAVEL_CITY')"
                    required
                  ></v-checkbox>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <v-checkbox
                    v-model="form.inActiveStatus"
                    :disabled="form.loading"
                    :label="$t('BRANCH.ADD_EDIT.IN_ACTIVE_STATUS')"
                    required
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <div class="col-12">
                  <v-btn
                    :disabled="!form.valid && form.loading"
                    color="success"
                    class="mr-4"
                    tile
                    type="submit"
                  >
                    <v-icon left>la-save</v-icon>
                    {{ $t("SHARED.SAVE_BUTTON") }}
                  </v-btn>
                  <v-btn
                    :disabled="form.loading"
                    color="default"
                    class="mr-4"
                    type="reset"
                    tile
                    @click.prevent="resetForm"
                  >
                    <v-icon left>mdi-eraser</v-icon>
                    {{ $t("SHARED.RESET_BUTTON") }}
                  </v-btn>
                </div>
              </v-row>

              <v-dialog v-model="form.dialog" persistent max-width="300">
                <v-card>
                  <v-card-title class="headline">
                    {{
                      $t("BRANCH.ADD_EDIT.SUCCESS_DIALOG_HEADER")
                    }}</v-card-title
                  >
                  <v-card-text>
                      {{ $t("BRANCH.ADD_EDIT.EDIT_SUCCESS_DIALOG_TEXT") }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="closeDialog"
                      >{{ $t("SHARED.CLOSE_BUTTON") }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="form.loading"
                persistent
                hide-overlay
                width="300"
              >
                <v-card>
                  <v-card-title class="headline">
                    {{ $t("SHARED.PLEASE_WAIT") }}</v-card-title
                  >
                  <v-card-text>
                    <p>
                      {{ $t("SHARED.PROCESSING") }}
                    </p>
                    <v-progress-linear
                      indeterminate
                      color="amber lighten-3"
                      class="mb-3"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-form>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";

export default {
  components: {
    KTPortlet,
  },
  data() {
    return {
      form: {
        valid: true,
        loading: false,
        dialog: false,
        errors: [],
        branchCode: "",
        nameEN: "",
        nameTH: "",
        floor: "",
        phoneNumber: "",
        isTravelCity: true,
        latitude: "",
        longitude: "",
        inActiveStatus: true,
        picture: null,
        pictureUrl: null,
        pictureType: [ "image/jpeg", "image/png" ],
        branchId: null,
        branchCodeRules: [
          (v) => !!v || this.$t("BRANCH.ADD_EDIT.BRANCH_CODE_VALIDATION"),
          (v) => (v && v.length <= 50) || this.$t("BRANCH.ADD_EDIT.BRANCH_CODE_COUNTER"),
        ],
        nameENRules: [
          (v) => !!v || this.$t("BRANCH.ADD_EDIT.NAME_EN_VALIDATION"),
          (v)  => (v && v.length <= 200) || this.$t("BRANCH.ADD_EDIT.NAME_EN_COUNTER"),
        ],
        nameTHRules: [
          (v)  => !!v || this.$t("BRANCH.ADD_EDIT.NAME_TH_VALIDATION"),
          (v)  => (v && v.length <= 200) || this.$t("BRANCH.ADD_EDIT.NAME_TH_COUNTER"),
        ],
        floorRules: [
          (v)  => !!v || this.$t("BRANCH.ADD_EDIT.FLOOR_VALIDATION"),
          (v)  => (v && v.length <= 100) ||  this.$t("BRANCH.ADD_EDIT.FLOOR_COUNTER"),
        ],
        phoneNumberRules: [
          (v)  => (!v || (v && v.length <= 50)) || this.$t("BRANCH.ADD_EDIT.PHONE_NUMBER_COUNTER"),
        ],
        latitudeRules: [
          (v)  => (!v || (v && v.length <= 50)) || this.$t("BRANCH.ADD_EDIT.LATITUDE_COUNTER"),
        ],
        longitudeRules: [
          (v)  => (!v || (v && v.length <= 50)) || this.$t("BRANCH.ADD_EDIT.LONGITUDE_COUNTER"),
        ],
      },
    }
  },
  methods: {
    getFileBase64(file){
      return new Promise(resolve => {
        let reader = new FileReader();
        reader.onload = () => {
          resolve({
              url: reader.result,
              base64: reader.result.split(',')[1],
              contentType: file.name.split('.')[1],
              size: file.size,
            });
        };
        reader.readAsDataURL(file);
      });
    },
    getImageMetadata(file){
      return new Promise(reslove => {
        let image = new Image();
        image.onload = () => {
          //console.log(image);
          reslove({
            w: image.width,
            h: image.height,
          });
        };
        image.src = file;
      });
    },
    submitForm() {
      var chk = this.$refs.form.validate();
      console.log("a")

      if(this.form.picture){
        if(!this.form.pictureType.includes(this.form.picture.type)){
          console.log("b")
          this.form.errors.push("กรุณาเลือกรูป Format .jpg, .jpeg, .png เท่านั้น");
        }

      this.getFileBase64(this.form.picture).then((file) => {
        this.getImageMetadata(file.url).then(() => {
          console.log("c")
          chk = false;
        });
        if(chk) 
            {
              this.postDataToApi(file);
            } 
      });
    } else{
        this.postDataToApi(null);
    }
    },
    resetForm() {
      this.$refs.form.reset();
    },
    postDataToApi(fileBase64TH) {
      this.form.loading = true;
      this.form.errors = [];

      ApiService.setHeader();
      ApiService.put("/Api/Branch/Edit", {
        BranchId: this.form.branchId,
        BranchCode: this.form.branchCode,
        NameEN: this.form.nameEN,
        NameTH: this.form.nameTH,
        Floor: this.form.floor,
        PhoneNumber: this.form.phoneNumber,
        IsTravelCity: this.form.isTravelCity,
        Latitude: this.form.latitude,
        Longitude: this.form.longitude,
        InActiveStatus: !this.form.inActiveStatus,
        Picture: fileBase64TH == null ? null : fileBase64TH.base64,
        ContentType: fileBase64TH == null ? null : fileBase64TH.contentType,
      })
        .then(({ data }) => {
          if (data.status) {
            // close dialog
            this.form.dialog = true;
          } else {
            this.form.dialog = false;
            this.form.loading = false;
            this.form.errors.push(!!data.message || "Unknow error occurs");
            this.$vuetify.goTo(this.$refs.alert,{duration : 1000, easing: 'easeInOutCubic', offset: -20});
          }
        })
        .catch(({ response }) => {
          if (response.data) {
            this.form.errors.push(response.data.message);
          } else {
            this.form.errors.push("Unknow error occurs");
          }
          this.$vuetify.goTo(this.$refs.alert,{duration : 1000, easing: 'easeInOutCubic', offset: -20});
          this.form.dialog = false;
          this.form.loading = false;
        });
    },
    closeDialog() {
      // not close but redirect to search page
      this.$router.push({ name: "SearchBranch" });
    },
    getDataFromApi(id) {
      this.form.loading = true;
      return new Promise(() => {
        ApiService.setHeader();
        ApiService.get("/Api/Branch", id)
          .then(({ data }) => {
            this.form.branchId = data.branchId;
            this.form.branchCode = data.branchCode;
            this.form.nameEN = data.nameEN;
            this.form.nameTH = data.nameTH;
            this.form.floor = data.floor;
            this.form.phoneNumber = data.phoneNumber;
            this.form.isTravelCity = data.isTravelCity;
            this.form.latitude = data.latitude;
            this.form.longitude = data.longitude;
            this.form.inActiveStatus = !data.inActiveStatus;
            this.form.pictureUrl = data.downloadFileByteUrl;
          })
          .finally(() => {
            this.form.loading = false;
          });
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.BRANCH.SECTION"), route: "/Branch" },
      { title: this.$t("MENU.BRANCH.EDIT") },
    ]);
    this.getDataFromApi(this.$route.params.id);
  },
  computed: {
    title() {
      return this.$t("MENU.BRANCH.EDIT");
    },
  },
}
</script>

<style lang="scss" scoped></style>
